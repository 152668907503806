import './LocationsMenuNavItem.scss';

import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Badge from 'fe-design-base/molecules/Badge';
import { List } from 'immutable';
import { omit, partial } from 'lodash';
import PropTypes from 'prop-types';

import {
  showAdditionalPayrollLocationModal,
  showModal,
  showNewLocationModal,
  showNewLocationsModal,
  showUnsupportedMultiLocationModal,
} from 'actions/modals';

import {
  getCurrentCompany,
  getCurrentCompanyId,
  getCurrentLocationId,
  getCurrentLocationName,
  getCurrentLocationWithArchived,
  getCurrentUser,
  getHasPayrollEnabled,
  getMultiLocationTrialEnabled,
  getPayrollMultiLocationPhaseTwoRollout,
} from 'selectors/session';

import {
  selectCompanyBillerName,
  selectShowSelfLocationCreate,
  selectShowUpgradeToAddLocation,
} from 'features/biller/selectors';
import { newLocation } from 'features/biller/slice';
import {
  buildTeamAppTeamCalendlyUrl,
  buildUtmParams,
  calendlyUrlBuilderCommand,
} from 'features/calendlyEnterprise';

import Box from 'components/Box';
import CalendlyEnterpriseModal from 'components/CalendlyEnterpriseModal';
import Button from 'components/clickables/Button';
import ClickAwayWrapper from 'components/ClickAwayWrapper';
import Icon from 'components/Icon';
import Popper from 'components/Popper';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import {
  trackMonetizationCtaEventKinesis,
  trackMultilocationTrialEvent,
  trackUxEvent,
} from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTIONS,
  TRACK_EVENTS,
  TRACK_SOURCES,
} from 'util/tracking_constants';

import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';

import { LockedLocations } from './LockedLocations';
import { UpgradeToAccessLocations } from './UpgradeToAccessLocations';

const PHONE = '415-951-3831';

@withNavItemData('locationsMenu')
@connect(
  (state, props) => ({
    currentCompanyId: getCurrentCompanyId(state),
    currentLocationId: getCurrentLocationId(state, props),
    currentLocationName: getCurrentLocationName(state, props),
    showSelfLocationCreateLink: selectShowSelfLocationCreate(state, props),
    showUpgradeToAddLocation: selectShowUpgradeToAddLocation(state, props),
    companyBillerName: selectCompanyBillerName(state, props),
    multiLocationTrial: getMultiLocationTrialEnabled(state, props),
    payrollMultiLocationPhaseTwoEnabled:
      getPayrollMultiLocationPhaseTwoRollout(state),
    currentLocation: getCurrentLocationWithArchived(state, props),
    currentCompany: getCurrentCompany(state, props),
    hasPayrollEnabled: getHasPayrollEnabled(state),
    currentUser: getCurrentUser(state, props),
    unreadMessageIndicator: state.messaging?.unreadMessageIndicator || [],
  }),
  {
    showModal,
    showNewLocationModal,
    showNewLocationsModal,
    showUnsupportedMultiLocationModal,
    showAdditionalPayrollLocationModal,
    newLocations: newLocation,
  }
)
@cxHelpers('LocationsMenuNavItem')
export default class LocationsMenuNavItem extends PureComponent {
  static propTypes = {
    currentLocationId: PropTypes.number,
    currentCompanyId: PropTypes.number,
    currentLocationName: PropTypes.string,
    showModal: PropTypes.func.isRequired,
    locations: PropTypes.instanceOf(List),
    showSelfLocationCreateLink: PropTypes.bool,
    showUpgradeToAddLocation: PropTypes.bool,
    companyBillerName: PropTypes.string,
    showNewLocationModal: PropTypes.func.isRequired,
    showNewLocationsModal: PropTypes.func.isRequired,
    showAdditionalPayrollLocationModal: PropTypes.func,
    payrollMultiLocationPhaseTwoEnabled: PropTypes.bool,
    multiLocationTrial: PropTypes.bool,
    newHeader: PropTypes.bool,
    newLocations: PropTypes.func.isRequired,
    currentLocation: ImmutablePropTypes.map,
    currentCompany: ImmutablePropTypes.map,
    hasPayrollEnabled: PropTypes.bool,
    currentUser: ImmutablePropTypes.map,
    unreadMessageIndicator: PropTypes.array,
  };

  state = {
    open: false,
    name: this.props.currentLocation?.get('name'),
    calendlyUrl: null,
  };

  receiveAnchorEl = element => this.setState({ anchorEl: element });

  handleClick = event => {
    // This prevents ghost click.
    event.preventDefault();

    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.LOCATION_DROPDOWN_CLICKED,
    });

    this.setState({ open: true });
  };

  handleRequestClose = () => this.setState({ open: false });

  handleAddNewLocation = () => {
    this.handleRequestClose();

    if (this.props.hasPayrollEnabled) {
      if (this.props.payrollMultiLocationPhaseTwoEnabled) {
        return this.props.showAdditionalPayrollLocationModal();
      }
      return this.props.showUnsupportedMultiLocationModal();
    }

    trackMonetizationCtaEventKinesis(
      TRACK_EVENTS.ADD_LOCATION,
      TRACK_ACTIONS.CLICKED_ADD_LOCATION,
      { url: window.location.href }
    );

    if (this.props.multiLocationTrial) {
      trackMultilocationTrialEvent(
        TRACK_EVENTS.ADD_LOCATION,
        TRACK_ACTIONS.ADD,
        {
          source: TRACK_SOURCES.DROPDOWN,
        }
      );
      return this.props.showNewLocationModal();
    }

    return this.props.newLocations();
  };

  handleChangeLocation = (name, id, e) => {
    e.preventDefault();
    window.Homebase.RailsReactBridge.changeLocation(id);
    this.handleRequestClose();
    this.setState({ name });
  };

  handleCalendlyEnterpriseClose = () => {
    this.setState({ calendlyUrl: null });
  };

  handleChatWithUs = () => {
    const { currentUser } = this.props;
    const calendlyUrl = buildTeamAppTeamCalendlyUrl(
      calendlyUrlBuilderCommand({
        currentUser,
        queryParams: buildUtmParams('in_trial_dashboard', null),
      })
    );

    trackMultilocationTrialEvent(TRACK_EVENTS.CS_CONTACT, TRACK_ACTIONS.CHAT, {
      source: TRACK_SOURCES.DROPDOWN,
    });

    this.setState({ calendlyUrl });
  };

  render() {
    const { name } = this.state;
    const {
      currentLocation,
      locations,
      showSelfLocationCreateLink,
      showUpgradeToAddLocation,
      multiLocationTrial,
      whiteText,
      newHeader,
      unreadMessageIndicator,
      ...boxProps
    } = omit(
      this.props,
      'currentLocationId',
      'currentCompany',
      'currentUser',
      'currentCompanyId',
      'newLocations',
      'showUnsupportedMultiLocationModal',
      'hasPayrollEnabled',
      'currentLocationName',
      'dispatch',
      'showNewLocationModal',
      'showModal',
      'experimentNewBilling',
      'showNewLocationsModal',
      'currentCompanyId',
      'currentLocationId',
      'companyBillerName',
      'payrollMultiLocationPhaseTwoEnabled',
      'showAdditionalPayrollLocationModal'
    );
    const lockedLocations = locations.filter(location =>
      location.get('show_locked_state')
    );

    return (
      <Box {...boxProps} className={this.cx()} getRef={this.receiveAnchorEl}>
        <NavItem
          className={this.cxEl('current-location', '', 'heap--navbar-18')}
          whiteText
          onClick={this.handleClick}
          persistMobileNavOnClick
        >
          <Box vcenter>
            <Box maxw="16vw" mrxxxs ellipsis>
              <Text fs={newHeader ? 18 : null} capitalize lh={1.2}>
                {name}
              </Text>
            </Box>
            <Icon type={this.state.open ? 'caretUp' : 'caretDown'} />
          </Box>
        </NavItem>
        <Popper
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          placement="bottom-end"
        >
          <Paper>
            <ClickAwayWrapper onClickAway={this.handleRequestClose}>
              <MenuList>
                {locations
                  .filter(
                    location =>
                      location.get('id') !== currentLocation?.get('id') &&
                      !location.get('show_locked_state')
                  )
                  .entrySeq()
                  .map(([key, location]) => {
                    const locationName = location.get('name');
                    const currentLocationIndicator =
                      unreadMessageIndicator.find(
                        indicator => indicator.location.name === locationName
                      );

                    return (
                      <MenuItem
                        key={key}
                        className={this.cxEl('location')}
                        onClick={partial(
                          this.handleChangeLocation,
                          locationName,
                          location.get('id')
                        )}
                      >
                        <a className="heap--navbar-19">
                          <Box
                            gap={12}
                            h={34}
                            vcenter
                            opacity={location.get('archived') ? 0.5 : 1}
                          >
                            <Text capitalize>{locationName}</Text>
                          </Box>
                          {currentLocationIndicator &&
                            currentLocationIndicator.location.unreadCount >
                              0 && <Badge variant="loud" />}
                        </a>
                      </MenuItem>
                    );
                  })}

                {this.state.calendlyUrl && (
                  <CalendlyEnterpriseModal
                    url={this.state.calendlyUrl}
                    open={this.state.calendlyUrl}
                    handleCloseModal={this.handleCalendlyEnterpriseClose}
                    origin="locations_menu_nav_item"
                  />
                )}

                {showSelfLocationCreateLink && (
                  <MenuItem
                    onClick={this.handleAddNewLocation}
                    className={this.cxEl('add-location')}
                  >
                    <a className="heap--navbar-26">
                      <Box h={47} vcenter>
                        <Text i18n="nav_links.add_location" capitalize />
                      </Box>
                    </a>
                  </MenuItem>
                )}

                {multiLocationTrial && (
                  <Box ph16 pt24 bt>
                    <Box mb24>
                      <Text
                        fs16
                        fw600
                        i18n="nav_links.multi_location_block.title"
                      />
                    </Box>
                    <Box mb16>
                      <Text fs14>{PHONE}</Text>
                    </Box>
                    <Box pb16>
                      <Button
                        theme="link"
                        i18n="common.cs_contact.book_a_call"
                        onClick={this.handleChatWithUs}
                      />
                    </Box>
                  </Box>
                )}
                {showUpgradeToAddLocation && <UpgradeToAccessLocations />}
                {!!lockedLocations.size && (
                  <LockedLocations locations={lockedLocations} />
                )}
              </MenuList>
            </ClickAwayWrapper>
          </Paper>
        </Popper>
      </Box>
    );
  }
}
