import { useSelector } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';

import { getTimeOffPolicyEnhancementsEnabled } from 'selectors/session';

import { FORM_WITH_STEPS_BOX_STYLE } from 'features/team/components/AddEmployeeForm/constants';

import { cxHelpers } from 'util/className';

import SectionTitle from '../../components/SectionTitle';

import PolicySelection from './PolicySelection';
import { TimeOffPolicyOptions } from './types';

const { cx } = cxHelpers('TimeOffSection');

const TimeOffSection = ({
  showFormWithSteps = false,
}: {
  showFormWithSteps: boolean;
}) => {
  const rolloutEnabled = useSelector(getTimeOffPolicyEnhancementsEnabled);
  const ptoPolicyOptions: TimeOffPolicyOptions[] = [
    {
      value: 'none',
      label: 'No Policy',
    },
    {
      value: 1,
      label: 'PTO 1',
    },
    {
      value: 2,
      label: 'PTO 2',
    },
  ];

  const sickLeavePolicyOptions: TimeOffPolicyOptions[] = [
    {
      value: 'none',
      label: 'No Policy',
    },
    {
      value: 3,
      label: 'Sick Leave 1',
    },
    {
      value: 4,
      label: 'Sick Leave 2',
    },
  ];

  if (!rolloutEnabled) {
    return null;
  }

  if (ptoPolicyOptions.length === 1 && sickLeavePolicyOptions.length === 1) {
    return null;
  }

  return (
    <Box
      className={cx()}
      mv={28}
      {...(showFormWithSteps && FORM_WITH_STEPS_BOX_STYLE)}
    >
      <SectionTitle section="time_off" />
      {ptoPolicyOptions.length > 1 && (
        <PolicySelection
          timeOffPolicyOptions={ptoPolicyOptions}
          policyType="pto_policy"
        />
      )}
      {sickLeavePolicyOptions.length > 1 && (
        <PolicySelection
          timeOffPolicyOptions={sickLeavePolicyOptions}
          policyType="paid_sick_leave_policy"
        />
      )}
    </Box>
  );
};

export default TimeOffSection;
