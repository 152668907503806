import {
  trackBingEvent,
  trackEvent,
  trackGoogleConversionCompanyCreation,
  trackGoogleConversionOwnerSignUp,
  trackLinkedInSignUp,
  trackUxEvent,
} from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import {
  amplitudeIdentify,
  amplitudeSetUserId,
} from 'util/trackingModules/amplitude';

export const userPayload = ({ fullName, email, password, phone }) => {
  const [firstName, ...lastName] = fullName.trim().split(' ');
  return {
    first_name: firstName,
    last_name: lastName.join(' '),
    email,
    password,
    phone,
  };
};

export const createBusinessTracking = ({
  company: company_resp,
  location: { id: locationId },
  user: { id: userId },
  uxTrackingData,
  amplitudeGroupIdentifyData,
}) => {
  window.Homebase.uxTrackingData = uxTrackingData;
  const idParams = {
    company_id: company_resp.id,
    location_id: locationId,
  };
  amplitudeSetUserId(userId);
  amplitudeIdentify({ ...amplitudeGroupIdentifyData });

  trackUxEvent({
    eventAction: EVENT_ACTIONS.OWNER_SIGN_UP,
    productArea: PRODUCT_AREAS.ONBOARDING,
    eventCategory: EVENT_CATEGORIES.ONBOARDING,
    actionType: TRACK_ACTION_TYPES.VIEW,
  });

  // Old event naming convention required here to
  // keep parity with existing GA flow
  trackEvent('Owner Signed Up', idParams, {
    ga: true,
    fb: true,
    heap: true,
  });

  trackEvent(
    'Company Created',
    { company_id: company_resp.id },
    {
      ga: true,
      fb: true,
      heap: true,
    }
  );

  // Both "Company..." and "Location..." required separately
  trackGoogleConversionCompanyCreation(company_resp);
  trackGoogleConversionOwnerSignUp();
  trackBingEvent('owner-signup');
  trackLinkedInSignUp();
};
