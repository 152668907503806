import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteJSON, fetchJSON, postJSON } from 'api/fetch';
import { RootState } from 'initializers/types';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { formatShift } from './YourSchedule/helpers';
import { MY_WEEK_SLICE } from './constants';

export type PendingState = 'pending' | 'rejected' | 'accepted' | 'approved';
interface EmployeeResponse {
  id: number;
  full_name: string;
}

interface TradeShiftResponse {
  id: number;
  role_name: string;
  start_at: string;
  end_at: string;
  user: { full_name: string };
}

export interface Shift {
  id: number;
  unscheduled: boolean;
  owner_id: number;
  owner_type: 'Job' | 'Location';
  start_at: string;
  end_at: string;
  role_name: string | null;
}

interface Job {
  id: number;
  location_id: number;
  location_name: string;
  enrolled_pto_policies?: any[];
  no_policy_category_names?: string[];
}

export interface ShiftWithLocation extends Shift {
  location_name: string;
}

export interface Trade {
  id: number;
  state: PendingState;
  job_id: number;
  reason: string;
  shift: {
    id: number;
    unscheduled: boolean;
    start_at: string;
    end_at: string;
    role_name: string;
  };
  trade_shift?: {
    id: number;
    unscheduled: boolean;
    start_at: string;
    end_at: string;
    role_name: string;
  };
  location_name: string;
  created_at: string;
  requester_first_name: string;
  requester_last_name: string;
  receiver_first_name: string;
  receiver_name: string;
  responder_name: string | null;
}

export interface DateRange {
  start_date: string;
  end_date: string;
}

export interface TotalsData {
  scheduled_hours: number;
  scheduled_costs: number;
  paid: number;
  costs: number;
}

export interface PTOPolicy {
  pto_balance?: number;
  accrued?: number;
  max_accrued?: number;
  used?: number;
  category_name: string;
}

interface UserData {
  id: number;
  totals: [TotalsData, TotalsData]; // [previous, current]
  pto_policies_data?: PTOPolicy[];
}

export interface TimeOff {
  id: number;
  state: string;
  category: string;
  category_name: string;
  normalized_category_name: string;
  job_id: number;
  whole_day: boolean;
  notes: string;
  approved: boolean;
  expired: boolean;
  date_range_text: string;
  time_off_day_durations: Array<{
    duration_in_hours: number;
    start_at: string;
  }>;
  start_at: string;
  end_at: string;
  created_at: string;
  updated_at: string;
  responder_name?: string | null;
}

export interface TimeOffUpdateAttrs {
  time_off: {
    start_at?: string;
    end_at?: string;
    job_id?: number;
    category?: string;
    notes?: string;
    whole_day?: boolean;
    unpaid_reason?: string;
    state?: string;
    time_off_day_durations?: Array<{
      duration_in_hours: number;
      start_at: string;
    }>;
  };
}

interface MyWeekState {
  userShifts: ShiftWithLocation[];
  openShifts: ShiftWithLocation[];
  selectedScheduleTab: 'my-schedule' | 'open-shifts';
  selectedTradesTab: 'incoming-requests' | 'your-requests';
  shiftsLoading: boolean;
  totalsLoading: boolean;
  timeOffsLoading: boolean;
  error: string | null;
  jobs: Job[];
  availableForCoverage: { label: string; value: number }[];
  availableForCoverageLoading: boolean;
  availableForTrade: { label: string; value: number }[];
  availableForTradeLoading: boolean;
  incomingTrades: Trade[];
  outgoingTrades: Trade[];
  tradesLoading: boolean;
  dateRange: DateRange;
  userData: UserData | null;
  timeOffs: TimeOff[];
}

const initialState: MyWeekState = {
  userShifts: [],
  openShifts: [],
  selectedScheduleTab: 'my-schedule',
  selectedTradesTab: 'incoming-requests',
  shiftsLoading: true,
  totalsLoading: true,
  timeOffsLoading: true,
  error: null,
  jobs: [],
  availableForCoverage: [],
  availableForCoverageLoading: false,
  availableForTrade: [],
  availableForTradeLoading: false,
  incomingTrades: [],
  outgoingTrades: [],
  tradesLoading: true,
  dateRange: {
    start_date: '',
    end_date: '',
  },
  userData: null,
  timeOffs: [],
};

export const fetchProfile = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchProfile`,
  async (params: DateRange) => {
    const profileParams = new URLSearchParams({
      ...params,
      format: 'json',
    });

    return fetchJSON(`/profile?${profileParams}`);
  }
);

export const fetchShifts = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchShifts`,
  async (params: DateRange) => {
    const shiftsParams = new URLSearchParams({
      ...params,
      only_future: 'true',
      format: 'json',
    });

    return fetchJSON(`/user/shifts?${shiftsParams}`);
  }
);

export const fetchAvailableForTrade = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchAvailableForTrade`,
  async (params: { shift_id: number }) => {
    const response = await fetchJSON(
      `/shifts/${params.shift_id}/for_trade.json`
    );

    return response.map((shift: TradeShiftResponse) => {
      const { date, time, roleName } = formatShift(shift);

      return {
        label: shift.user.full_name,
        subLabel: `${date} ${time} ${roleName}`,
        value: shift.id,
      };
    });
  }
);

export const fetchAvailableForCoverage = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchAvailableForCoverage`,
  async (params: { shift_id: number }) => {
    const response = await postJSON(`/jobs/available_for_cover.json`, params);

    // shape response data to be used in RequestCoverModal SelectField options
    return response.map((employee: EmployeeResponse) => ({
      label: employee.full_name,
      value: employee.id,
    }));
  }
);

export const fetchTrades = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchTrades`,
  async () => fetchJSON('/user/trades.json')
);

export const fetchTimeOffs = createAsyncThunk(
  `${MY_WEEK_SLICE}/fetchTimeOffs`,
  async () => fetchJSON('/user/time_offs')
);

export const deleteTimeOff = createAsyncThunk(
  `${MY_WEEK_SLICE}/deleteTimeOff`,
  async (timeOffId: number) => {
    await deleteJSON(`/time_offs/${timeOffId}`);

    return timeOffId;
  }
);

const addLocationToShifts = (
  shifts: Shift[],
  jobs: Job[]
): ShiftWithLocation[] =>
  shifts.map(shift => {
    const matchingJob =
      shift.owner_type === 'Job'
        ? jobs.find(job => job.id === shift.owner_id)
        : jobs.find(job => job.location_id === shift.owner_id);

    return {
      ...shift,
      location_name: matchingJob?.location_name || '',
    };
  });

const myWeekSlice = createSlice({
  name: MY_WEEK_SLICE,
  initialState,
  reducers: {
    setSelectedScheduleTab: (state, action) => {
      state.selectedScheduleTab = action.payload;
    },
    setSelectedTradesTab: (state, action) => {
      state.selectedTradesTab = action.payload;
    },
    removeTrade(state, action) {
      state.outgoingTrades = state.outgoingTrades.filter(
        trade => trade.id !== action.payload
      );
    },
    setDateRange(state, action) {
      state.dateRange = action.payload;
    },
    updateTimeOff(state, action) {
      const updatedTimeOff = action.payload;
      const index = state.timeOffs.findIndex(
        timeOff => timeOff.id === updatedTimeOff.id
      );
      state.timeOffs[index] = updatedTimeOff;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchShifts.pending, state => {
        state.shiftsLoading = true;
        state.error = null;
      })
      .addCase(fetchShifts.fulfilled, (state, action) => {
        // Store raw shifts first (locations not given by shifts endpoint)
        const { user_shifts, open_shifts } = action.payload;

        // If we already have jobs, add locations
        if (state.jobs.length) {
          state.userShifts = addLocationToShifts(user_shifts, state.jobs);
          state.openShifts = addLocationToShifts(open_shifts, state.jobs);
          state.shiftsLoading = false;
        } else {
          // Store shifts but keep loading true until we get locations
          state.userShifts = user_shifts.map((s: Shift) => ({
            ...s,
            location_name: '',
          }));
          state.openShifts = open_shifts.map((s: Shift) => ({
            ...s,
            location_name: '',
          }));
        }
      })
      .addCase(fetchProfile.pending, state => {
        state.shiftsLoading = true;
        state.totalsLoading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.jobs = action.payload.jobs;
        state.userData = action.payload.user;
        state.totalsLoading = false;

        if (state.userShifts.length || state.openShifts.length) {
          state.userShifts = addLocationToShifts(state.userShifts, state.jobs);
          state.openShifts = addLocationToShifts(state.openShifts, state.jobs);
        }
        state.shiftsLoading = false;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch profile';
        state.totalsLoading = false;
        state.shiftsLoading = false;
      })
      .addCase(fetchAvailableForCoverage.pending, state => {
        state.error = null;
        state.availableForCoverageLoading = true;
      })
      .addCase(fetchAvailableForCoverage.fulfilled, (state, action) => {
        state.availableForCoverage = action.payload;
        state.availableForCoverageLoading = false;
      })
      .addCase(fetchAvailableForCoverage.rejected, (state, action) => {
        state.error =
          action.error.message || 'Failed to fetch available employees';
        state.availableForCoverageLoading = false;
      })
      .addCase(fetchAvailableForTrade.pending, state => {
        state.error = null;
        state.availableForTradeLoading = true;
      })
      .addCase(fetchAvailableForTrade.fulfilled, (state, action) => {
        state.availableForTrade = action.payload;
        state.availableForTradeLoading = false;
      })
      .addCase(fetchAvailableForTrade.rejected, (state, action) => {
        state.error =
          action.error.message || 'Failed to fetch available for trade';
        state.availableForTradeLoading = false;
      })
      .addCase(fetchTrades.pending, state => {
        state.tradesLoading = true;
      })
      .addCase(fetchTrades.fulfilled, (state, action) => {
        const { incoming_trades, outgoing_trades } = action.payload;
        state.incomingTrades = incoming_trades;
        state.outgoingTrades = outgoing_trades;
        state.tradesLoading = false;
      })
      .addCase(fetchTrades.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch trades';
        state.tradesLoading = false;
      })
      .addCase(fetchTimeOffs.pending, state => {
        state.timeOffsLoading = true;
        state.error = null;
      })
      .addCase(fetchTimeOffs.fulfilled, (state, action) => {
        state.timeOffs = action.payload;
        state.timeOffsLoading = false;
      })
      .addCase(fetchTimeOffs.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch time offs';
        state.timeOffsLoading = false;
      })
      .addCase(deleteTimeOff.pending, state => {
        state.error = null;
      })
      .addCase(deleteTimeOff.fulfilled, (state, action) => {
        state.timeOffs = state.timeOffs.filter(
          timeOff => timeOff.id !== action.payload
        );
      })
      .addCase(deleteTimeOff.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to delete time off';
      });
  },
});

// Selectors
export const selectUserShifts = (state: RootState) =>
  state.get(MY_WEEK_SLICE).userShifts;

export const selectOpenShifts = (state: RootState) =>
  state.get(MY_WEEK_SLICE).openShifts;

export const selectSelectedScheduleTab = (state: RootState) =>
  state.get(MY_WEEK_SLICE).selectedScheduleTab;

export const selectSelectedTradesTab = (state: RootState) =>
  state.get(MY_WEEK_SLICE).selectedTradesTab;

export const selectShiftsLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).shiftsLoading;

export const selectShiftsError = (state: RootState) =>
  state.get(MY_WEEK_SLICE).error;

export const selectAvailableForCoverage = (state: RootState) =>
  state.get(MY_WEEK_SLICE).availableForCoverage;

export const selectAvailableForCoverageLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).availableForCoverageLoading;

export const selectAvailableForTrade = (state: RootState) =>
  state.get(MY_WEEK_SLICE).availableForTrade;

export const selectAvailableForTradeLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).availableForTradeLoading;

export const selectIncomingTrades = (state: RootState) =>
  state.get(MY_WEEK_SLICE).incomingTrades;

export const selectOutgoingTrades = (state: RootState) =>
  state.get(MY_WEEK_SLICE).outgoingTrades;

export const selectTradesLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).tradesLoading;

export const selectJobs = (state: RootState) => state.get(MY_WEEK_SLICE).jobs;

export const selectJobsForTimeOffModal = createSelector(
  [selectJobs],
  (jobs: Job[]) =>
    jobs.map((job: Job) => ({
      id: job.id,
      name: job.location_name,
      enrolledPtoPolicies: job.enrolled_pto_policies || [],
      noPolicyCategoryNames: job.no_policy_category_names || [],
    }))
);

export const selectDateRange = (state: RootState) =>
  state.get(MY_WEEK_SLICE).dateRange;

export const selectUserData = (state: RootState) =>
  state.get(MY_WEEK_SLICE).userData;

export const selectUserTotals = createCachedSelector(
  [selectUserData],
  userData => userData?.totals
)(() => 'userTotals');

export const selectIsDateRangeLoading = createSelector(
  [selectDateRange],
  dateRange => !dateRange.start_date || !dateRange.end_date
);

export const selectJobByLocationId = createCachedSelector(
  [selectJobs, (_: RootState, locationId: number) => locationId],
  (jobs: Job[], locationId: number) =>
    jobs.find(job => job.location_id === locationId)
)((_: RootState, locationId: number) => `selectJobByLocationId-${locationId}`);

export const selectTotalsLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).totalsLoading;

export const selectTimeOffs = (state: RootState) =>
  state.get(MY_WEEK_SLICE).timeOffs;

export const selectTimeOffsLoading = (state: RootState) =>
  state.get(MY_WEEK_SLICE).timeOffsLoading;

export const selectPTOPolicies = (state: RootState) =>
  state.get(MY_WEEK_SLICE).userData?.pto_policies_data;

export const {
  setSelectedScheduleTab,
  setSelectedTradesTab,
  removeTrade,
  setDateRange,
  updateTimeOff,
} = myWeekSlice.actions;

export const { reducer } = myWeekSlice;
